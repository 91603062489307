import React, { useState } from 'react'
import '../../app.scss'
import { faqData } from './faqData';
import { Divider } from '@mui/material';
import useMedia from '../../hooks/useMedia';

function SectionFAQ({onScrollStatusChange}) {
    const [activeTab, setActiveTab] = useState(faqData[0].id);
    const [openAccordion, setopenAccordion] = useState({});
    const {md} = useMedia()
    const handleTabClick = (id) => {
        setActiveTab(id);
        setopenAccordion({});
    };

    // const handleScroll = (e) => {
    //     console.log(md)
    //     // const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    //     // if (scrollTop === 0 || scrollTop + clientHeight >= scrollHeight) {
    //       // Unlock parent scroll when at the start or end of the child scroll
    //       if(!md)
    //         onScrollStatusChange(true);
    //     // } else {
    //     //   // Lock parent scroll when child is actively scrolling
          
    //     // }
    //   };

    //   const handleScrollRemove = (e) => {
    //     if(!md)
    //     onScrollStatusChange(false);
    //   }

    const handleScroll = (e) => {
        if(!md){

        
        const { scrollTop, scrollHeight, clientHeight } = e.target;
    
        if (scrollTop === 0) {
            // User is at the start of the scrollable content
            console.log("start");
            onScrollStatusChange(false);
        } else if (scrollTop + clientHeight >= scrollHeight) {
            // User is at the end of the scrollable content
            console.log("end");
            onScrollStatusChange(false);
        } else {
            // User is somewhere in the middle
            console.log("middle");
            onScrollStatusChange(true);
        }
    }
    };
    const toggleAccordion = (faqIndex) => {
        setopenAccordion((prevState) => ({
        ...prevState,
        [faqIndex]: !prevState[faqIndex],
        }));
    };
    
    const currentTab = faqData.find((tab) => tab.id === activeTab);

    return (
        <div className='lg:min-h-[100dvh] lg:h-auto h-[85dvh] lg:block flex flex-col items-center justify-center'>
        <section className='  w-full lg:px-52 px-4 ' >
            <div className='faq-top flex justify-center lg:justify-between '>
                <h2 className='text-[20px] text-center sm:text-[64px] sm:text-start font-[700] text-[#10F9B1] ff-bold lg:mt-0'>
                    FAQ's
                </h2>
            </div>

            <div className='flex  w-[100%] '>
                <div className='lg:mt-[25px] w-full h-[85px]  flex  lg:-ml-5 justify-center items-center custom-scroll-2 bg-transparent'>
                    <div className="container-tab lg:overflow-hidden overflow-x-scroll">
                        <div className="tabs">
                            {faqData.map((tab) => (
                            <div
                                key={tab.id}
                                className={`tab ${activeTab === tab.id ? 'active-tab' : ''} text-[15px] md:text-[18px] `}
                                onClick={() => handleTabClick(tab.id)}
                            >
                                {tab.tabTitle}
                            </div>
                            ))}
                            <span
                            className="glider"
                            style={{ transform: `translateX(${(activeTab - 1) * 100}%)` }}
                            ></span>
                        </div>
                    </div>
                </div>
            </div>

                <div className="lg:mt-[50px] lg:grid-cols-2 lg:gap-x-10 lg:gap-y-2 h-[55vh] grid lg:h-auto lg:overflow-hidden overflow-scroll"  onScroll={handleScroll}>
                    {currentTab.faqs.map((faq, index) => (
                    <div key={index} className="w-full lg:w-1/2 px-2 mb-4 flex flex-col  accordion  ">
                        <button
                        className='flex justify-between items-center cursor-pointer '
                        onClick={() => toggleAccordion(index)}
                        >
                            <span className='accordion-top-text text-[15px] md:text-[21px] text-left '>
                            {faq.question}
                            </span>
                        
                        <div className='accordion-top-btn items-end'>
                            {openAccordion[index] ? <div>
                                <svg width="16" height="9" viewBox="0 0 16 9" className='close-accordion' fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.59529 8.49111L0.495602 1.32135C0.262718 1.08682 0.26351 0.705902 0.496394 0.472163C0.729404 0.238413 1.10775 0.238413 1.33995 0.472944L8.01825 7.21535L14.6958 0.472944C14.928 0.238413 15.3055 0.237632 15.5393 0.472163C15.6566 0.589423 15.7144 0.742114 15.7144 0.897148C15.7144 1.05062 15.6566 1.20409 15.5401 1.32135L8.44042 8.49111C8.32805 8.60444 8.17608 8.66739 8.01825 8.66739C7.85964 8.66739 7.70766 8.60365 7.59529 8.49111Z" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.59529 8.49111L0.495602 1.32135C0.262718 1.08682 0.26351 0.705902 0.496394 0.472163C0.729404 0.238413 1.10775 0.238413 1.33995 0.472944L8.01825 7.21535L14.6958 0.472944C14.928 0.238413 15.3055 0.237632 15.5393 0.472163C15.6566 0.589423 15.7144 0.742114 15.7144 0.897148C15.7144 1.05062 15.6566 1.20409 15.5401 1.32135L8.44042 8.49111C8.32805 8.60444 8.17608 8.66739 8.01825 8.66739C7.85964 8.66739 7.70766 8.60365 7.59529 8.49111Z" fill="#243146" />
                                </svg>
                            </div>: <div>
                                <svg width="16" height="9" viewBox="0 0 16 9" className='open-accordion' fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.59529 8.49111L0.495602 1.32135C0.262718 1.08682 0.26351 0.705902 0.496394 0.472163C0.729404 0.238413 1.10775 0.238413 1.33995 0.472944L8.01825 7.21535L14.6958 0.472944C14.928 0.238413 15.3055 0.237632 15.5393 0.472163C15.6566 0.589423 15.7144 0.742114 15.7144 0.897148C15.7144 1.05062 15.6566 1.20409 15.5401 1.32135L8.44042 8.49111C8.32805 8.60444 8.17608 8.66739 8.01825 8.66739C7.85964 8.66739 7.70766 8.60365 7.59529 8.49111Z" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.59529 8.49111L0.495602 1.32135C0.262718 1.08682 0.26351 0.705902 0.496394 0.472163C0.729404 0.238413 1.10775 0.238413 1.33995 0.472944L8.01825 7.21535L14.6958 0.472944C14.928 0.238413 15.3055 0.237632 15.5393 0.472163C15.6566 0.589423 15.7144 0.742114 15.7144 0.897148C15.7144 1.05062 15.6566 1.20409 15.5401 1.32135L8.44042 8.49111C8.32805 8.60444 8.17608 8.66739 8.01825 8.66739C7.85964 8.66739 7.70766 8.60365 7.59529 8.49111Z" fill="#243146" />
                                </svg>
                            </div> }
                        </div>

                        </button>
                        {openAccordion[index] && (
                        <div className='accordion-content text-[12px] md:text-[16px] mt-[30px] leading-[18px] md:leading-[28px] ' >
                            {faq.answer}
                        </div>
                        )}
                    </div>
                    ))}
                </div>
        </section>
        </div>
    )
}
export default SectionFAQ
