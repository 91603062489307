// src/FormComponent.js
import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
// import CustomButton from './CustomButton';
import HCaptcha from 'react-hcaptcha';
import OkDialog from './okDialog';
import LazyImage from './lazyImage';
import Turnstile, { useTurnstile } from "react-turnstile";

function ContactForm() {

  const turnstile = useTurnstile();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // const  setToken = useState('');
  const [verified, setVerified] = useState(false);
  const [sendClicked, setSendClicked] = useState(false);
  const [open, setOpen] = useState(false);

  // const isButtonDisabled = ;

  const handleVerificationSuccess = async(token) => {
    // setToken(token);
    setVerified(true);
    try {
      setErrors({});
      const response = await axios.post(apiUrl + '/guest', {
        title,
        content: description,
        phone,
        email,
        name,
      });
      setOpen(true);
      setMessage(response.data.code);
    } catch (error) {
      alert('Error submitting form');
    }
    setLoading(false);

  };
  // const handleNameChange = (e) => {
  //   const input = e.target.value;
  //   const onlyLetters = input.replace(/[^a-zA-Z\s]/g, '');
  //   setName(onlyLetters);
  // };
  const closeModal = () => {
    setOpen(false);
    setTitle('');
    setDescription('');
    setEmail('');
    setPhone('');
    setName('');
    setSendClicked(false)
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = async () => {

    if (!sendClicked) {
      
      
    
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[1-9]\d{9,11}$/;

    // if (!name) newErrors.name = 'Name is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!title) newErrors.title = 'Name is required';
    if (!description) newErrors.description = 'Message is required';
    if (phone && !phoneRegex.test(phone)) {
      newErrors.phone = 'Phone number must be between 10 to 12 digits';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    // setLoading(true);

    setSendClicked(true)
    return
  }
    
  };

  return (
    <Grid
      container
      // spacing={2}
      sx={{
        maxWidth: '1092px',
        width: '100%',
        p: '20px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '20px',
        margin: '0 auto',
        overflow: 'hidden'
      }}
    >
      <Grid item xs={12} md={6} sx={{ px: { xs: '10px', md: '30px' }, mt:"20px" }}>
        <Typography
          sx={{
            fontFamily: 'Blinker, sans-serif',
            fontSize: { xs: '17px', md: '30px' },
            fontWeight: '600',
            textAlign: 'left',
          }}
        >
          Let’s Align Our Constellations
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Blinker, sans-serif',
            fontSize: { xs: '16px', md: '20px' },
            fontWeight: '200',
            textAlign: 'left',
            maxWidth:{md:"425px"}
          }}
        >
          Reach out and let the magic of collaboration light up our shared skies.
        </Typography>
        <form className='flex flex-col items-center justify-center lg:w-[426px] w-[70vw] ' style={{ textAlign: 'center' }}>
        {!sendClicked && 
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                value={name}
                onChange={handleNameChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  style: { color: '#eee' },
                }}
                required
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid> */}

            
            <Grid item sx={{
              mt:{md:"15px"},
            }} xs={12} sm={12} >
              <TextField
                label="Name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  style: { 
                    color: '#eee',
                    opacity:"60%",
                   },
                }}
                required
                error={!!errors.title}
                helperText={errors.title}
              />
            </Grid>
            <Grid item sx={{
              mt: -4,
            }} xs={12}>
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  style: { 
                    color: '#eee',
                    opacity:"60%", 

                },
                }}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item sx={{
              mt: -4
            }} xs={12}>
              <TextField
                label="Phone"
                value={phone}
                type='number'
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone}
                InputLabelProps={{
                  style: { color: '#eee' ,
                    opacity:"60%",
                  },
                }}
              />
            </Grid>
            <Grid item sx={{
              mt: -4
            }} xs={12}>
              <TextField
                multiline
                rows={4}
                maxRows={5}
                label="Message"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.description}
                helperText={errors.description}
                InputLabelProps={{
                  style: { color: '#eee',
                    opacity:"60%", },
                }}
                inputProps={{ style: { color: 'white', fontFamily: 'blinker', fontSize: '16px !important', } }}
              />
            </Grid>


            <Grid item sx={{
              mt: -4
            }} xs={12}>
              <Button
                disabled={loading || !title || !description || !email || !phone }
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  width: {xl:'426px', xs:'100%'},
                  textTransform: 'none',
                  height:'42px',

                  my: { md: 2, xs: 4 },
                  fontSize: { md: '16px', xs: '12px' },
                  fontFamily: 'blinker',
                  backgroundColor: '#10F9B1 !important',
                  borderRadius:"4px !important",
                  '&:disabled': {
                    // backgroundColor:"#7A7D9C",
                    color: '#000',

                    // boxShadow: '1px 1px 5px #eee'
                  }
                }}
              >
                Send
              </Button>
            </Grid>
            {message && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: 'ButtonText',
                    py: 2,
                    fontFamily: 'blinker',
                    fontSize: { md: '24px', xs: '16px' },
                    textAlign: 'center',
                  }}
                >
                  Status Code: {message}
                </Typography>
              </Grid>
            )}

            
          </Grid>
}
{sendClicked && 
          <Grid item sx={{
              mt:'10vh',
            }} xs={12} >
              {/* <HCaptcha
                theme={"dark"}
                id="captchaCustom"
                sitekey="1c9e86fa-1a1a-49a2-ac1a-bd7a740ae79a"
                onVerify={handleVerificationSuccess}
              /> */}
              <Turnstile
                onSuccess={handleVerificationSuccess}
                sitekey="0x4AAAAAAAiKeM3zK0vt_YNQ"
                onVerify={(token) => {
                  fetch("/login", {
                    method: "POST",
                    body: JSON.stringify({ token }),
                  }).then((response) => {
                    if (!response.ok) turnstile.reset();
                  });
                }}
              />
            </Grid>
            }
            <OkDialog OpenModal={open} clicked={closeModal} />
            
        </form>
      </Grid>
      <Grid item xs={12} md={6}
      sx={{
        px:{xs:"20px"}
        }} >
        <LazyImage src="contactForm.png" alt="" 
        clss='rounded-2xl lg:mt-16'
        
        />
      </Grid>
    </Grid>
  );
}

export default ContactForm;
