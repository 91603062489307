import { Button } from '@mui/material'
import React from 'react'

export default function ServicesCustomButton() {
  return (
    <Button className='btn-header' href='/meeting' sx={{
        fontFamily : 'blinker',
        fontSize:{xl : '22px' , lg: '16px', xs : '16px' },
        fontWeight:'400',
        backgroundColor:'#10F9B1',
        width:{xl : '304px' ,lg:'202px', xs : '223px'},
        height:{xl : '75px' ,lg: '42px', xs : '49px'},
        color:'#000',
        // mt:{md:30,xs:20},
        textTransform: 'none',
        // '&:hover' : {
        //     backgroundColor:'#10F9B1',
        //     boxShadow:'1px 1px 10px #10F9B1'

        //   }
    }}>Get a Free Consultation</Button>
  )
}
