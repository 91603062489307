import { Box, Typography, Modal } from '@mui/material'
import React from 'react'

// import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function TicketModal({openModal}) {
  const router = useNavigate()
  const handleClose = () => {
    router(-1)
  };
  
  return (
    <>
    <Box sx={{
        mt:20
    }}>
      
    </Box>
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
            display:'flex', 
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center'
        }}
      >
        <Box sx={{
        //   position: 'absolute',
          top: { xs: '200px', md: '50vh'  },
          left: { xs: '41px', md: '10vw'   },
          fontFamily: 'blinker',
          textAlign:'center',
          backgroundColor: '#10F9B1',
          width: { xs: '310px', md: '907px' },
          height: { xs: '400px', md: '297px' },
          borderRadius: 8,
          display:'flex', 
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center'

        }}>
          <Typography sx={{
            fontSize:{md : '36px' , xs : '24px'},
            color:'#000',
            fontFamily:'blinker',
            fontWeight:'700'
          }}>
          Your ticket has been submitted successfully
          </Typography>
          <Typography sx={{
            fontSize:{md : '32px' , xs : '21px'},
            color:'#000',
            fontFamily:'blinker',
            fontWeight:'200',
            paddingTop:{xs:"30px", sm:"0"},
            
          }}>
          We will contact you soon.
          </Typography>
        </Box>
      </Modal>
    </div>
    
    </>
  )
}
// text-white text-center text-xl font-bold font-family:Blinker