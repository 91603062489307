import React from 'react'
import SeoHeroSection from './SeoHeroSection'
import SeoSectionTwo from './SeoSectionTwo'
import SeoSectionThree from './SeoSectionThree'
import SeoSectionFour from './SeoSectionFour'
import SeoSectionFive from './SeoSectionFive'
import SeoEndSection from './SeoEndSection'
import { TabTitle } from '../../utils/GeneralFunctions'

function SeoPage() {
  TabTitle("Seo")
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <SeoHeroSection />
      <SeoSectionTwo />
      <div className='relative seo-sec2-background-2 ' >
        <div className='sticky top-0'>
      <SeoSectionThree />
      <SeoSectionFour />
      <SeoSectionFive />
      </div></div>
      <SeoEndSection />
    </div>
  )
}

export default SeoPage
