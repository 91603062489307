import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/mousewheel";
import SwiperCore from "swiper";
import "swiper/css/effect-creative";
import { Mousewheel } from "swiper/modules";
import { EffectFade, EffectFlip, EffectCreative } from "swiper/modules";
import { Link } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import LazyImage from "../lazyImage";

import leftA from './left.png'
import rightA from './right.png'
// import services from "../services";
// import { Flag } from "@mui/icons-material";

SwiperCore.use([Mousewheel, EffectFade, EffectFlip, EffectCreative, Swiper]);




const SectionService = () => {


  const swiperRef = useRef(null)
  const nextSlide = () => {
    // console.log(swiperRef)
    // ChangeSlide(swiperRef)
    swiperRef.current.swiper.slideTo(activeIndex + 1)
    setActiveIndex(activeIndex + 1)
  }

  const prevSlide = () => {
    swiperRef.current.swiper.slideTo(activeIndex - 1)
    setActiveIndex(activeIndex - 1)
  }
  const  logit = () => {
    const x = h1Ref.current.getBoundingClientRect().y;
    const activeComp = serviceRefs.current[activeService].getBoundingClientRect().y
    if(scrollDirection === "down" &&  activeService !== 4 &&serviceRefs.current[activeService+1].getBoundingClientRect().y <= x){
      setActiveService(activeService+1)
      console.log(x , activeComp,activeService)
    }
    if(scrollDirection === "up" && activeService !== 0 &&serviceRefs.current[activeService -1 ].getBoundingClientRect().y >= (x -  (serviceRefs.current[activeService -1 ].getBoundingClientRect().height / 2))){
      setActiveService(activeService-1)
      console.log(x , activeComp,activeService)
    }
    // console.log(x , activeComp,activeService)



  }
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    function handleScroll() {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        setScrollDirection("down");
      } else if (currentScrollY < lastScrollY) {
        setScrollDirection("up");
      }

      lastScrollY = currentScrollY;
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });
  // useEffect(() => {
    
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //   //         const x = h1Ref.current.getBoundingClientRect().y;
  //   // const activeComp = serviceRefs.current[activeIndex].getBoundingClientRect() 
  //   // console.log(x , activeComp)
  //           const id = entry.target.dataset.id;
  //           setActiveService(Number(id)); // شناسه سرویس فعال را ذخیره می‌کنیم
  //         }
  //       });

  //     },
  //     { threshold: 1 } // وقتی حداقل 50% عنصر دیده شود، فعال شود
  //   );

  //   serviceRefs.current.forEach((ref) => {
  //     if (ref) observer.observe(ref);
  //   });
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);
  

  const h1Ref = useRef(null); // Create a ref
  const [height, setHeight] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0)
  const ChangeSlide = (e) => {
    setActiveIndex(e.activeIndex)
    // console.log(e)
  }
  const [fadeClass, setFadeClass] = useState('');
  const Services = [
    {
      id: 1,
      title: "Content Creation",
      description: "Planning engaging content for your audience is what we do best",
      color: "#9FFDE0",
      TextColor: "#10F9B1",
      icon: "Services/S1.png",
      background: "circle/circle-1.png"
    },
    {
      id: 2,
      title: "Branding",
      description: "Making your brand recognizable through visuals is our passion",
      color: "#BBC6F3",
      TextColor: "#657CDE",
      icon: "Services/S2.png",
      background: "circle/circle-2.png"
    },
    {
      id: 3,
      title: "Ads",
      description: "Creating captivating videos to showcase your brand is our forte",
      color: "#83E0F5",
      TextColor: "#2BC1E4",
      icon: "Services/S3.png",
      background: "circle/circle-3.png"
    },
    {
      id: 4,
      title: "SEO",
      description: "Helping you rank higher on search engines is what we love to do",
      color: "#BBFCCB",
      TextColor: "#54F77E",
      icon: "Services/S4.png",
      background: "circle/circle-4.png"
    },
    {
      id: 5,
      title: "Web design",
      description: "Building a unique website tailored to your brand is our specialty",
      color: "#D9B4FE",
      TextColor: "#9951E1",
      icon: "Services/S5.png",
      background: "circle/circle-5.png"
    },
  ]

  const services = [
    { bgColor: "#10F9B1", titleColor: "#10F9B1", title: "WHAT WE DO", url: "/content-creation", description: "Content Creation", color: "#000", animation: 'fadeIn 1s forwards' },
    { bgColor: "#657CDE", titleColor: "#657CDE", title: "WHAT WE DO", url: "/branding", description: "Branding", color: '#fff', animation: 'fadeIn 1s forwards' },
    { bgColor: "#2BC1E4", titleColor: "#2BC1E4", title: "WHAT WE DO", url: "/ads", description: "Ads", color: '#fff', animation: 'fadeIn 1s forwards' },
    { bgColor: "#54F77E", titleColor: "#54F77E", title: "WHAT WE DO", url: "/seo", description: "SEO", color: "#000", animation: 'fadeIn 1s forwards' },
    { bgColor: "#9951E1", titleColor: "#9951E1", title: "WHAT WE DO", url: "/web-design", description: "Web Design", color: '#fff', animation: 'fadeIn 1s forwards' },
  ];




  const [activeService, setActiveService] = useState(0); // ذخیره شناسه سرویس فعال

  // const [animationTrigger, setAnimationTrigger] = useState(services[activeService].animation);

  useEffect(() => {
    setFadeClass('');
    setTimeout(() => {
      setFadeClass('fade-in');
    }, 1);
  }, [activeService]);
  const serviceRefs = useRef([]);
  const endOfAnotherComponentRef = useRef()
  return (
    <section className="">

      <div id="service-mobile-1"></div>
      {/* <div id="service-mobile-1" className="mt-[-600px] h-[450px]"></div> */}
      <div style={{ height: "auto" }} className="hidden xl:block mt-10 relative" id="service">
        <div className="absolute z-[0] top-0 right-0 w-[100%]  mt-[-150px]">
          <div className="relative z-0 top-[10%] h-auto">
            <div className="z-0 sticky w-[100%] h-[170vh] top-[20%] right-0">
              <div className="section1-background section1-services w-[70%]  blur-[21px]"></div>
            </div>
          </div>
        </div>

        <div className="relative flex">
          {/* عناصر استاتیک */}
          <div class="transition text-big sticky top-10 left-0 w-[100%] h-[100vh] flex  justify-start items-center">
            <LazyImage src="Flags/Flag-1.png" alt="" clss="absolute left-0 z-30 h-[95vh]" />
            <div class="h-[311px] w-[311px] rounded-full absolute left-[-220px] z-10 transition-04" id="service-style"></div>
            <div className="absolute z-40 left-40" id="service-content"></div>
            <div
              className="h-[311px] w-[311px] rounded-full absolute left-[-220px] z-10 transition-3"
              id="service-style"
              style={{ backgroundColor: activeService !== null ? services[activeService].bgColor : "#fff" }}
            ></div>
            <div className="absolute z-40 left-60 text-left" ref={h1Ref} id="service-content">
              {activeService !== null && (
                <>
                  <h1 className="text-[59px] font-extrabold "  style={{ color: services[activeService].titleColor, fontFamily: 'blinker', fontWeight: '800', transition: '0.5s ease' }}>
                    {/* {services[activeService].title} */}WHAT WE DO 
                  </h1>
                  
                  
                  <p className={`text-[43px] ${fadeClass}`} style={{ // Animation applied here
                  }}>{services[activeService].description}</p>
                  <Divider variant="fullWidth" component="div" sx={{
                    backgroundColor: 'white',
                    width: '60px'
                  }} />
                  <Button href={services[activeService].url} sx={{
                    width: '122px',
                    height: '50px',
                    fontSize: '18px',
                    fontFamily: 'blinker',
                    borderRadius: '10px',
                    fontWeight: '500',
                    textTransform: 'none',
                    color: services[activeService].color,
                    backgroundColor: services[activeService].bgColor,
                    '&:hover': {
                      backgroundColor: services[activeService].bgColor,
                      boxShadow: `1px 1px 5px ${services[activeService].bgColor}`
                    }
                  }} variant="contained">Learn More</Button>
                </>
              )}
            </div>
          </div>



          {/* لیست خدمات */}
          <div className="flex flex-col w-full mt-80 mr-20">
            <div className="text-[96px] font-bold text-end -mt-28 mr-20 mb-4" style={{ color: Services[0].TextColor }}>0{Services[0].id}</div>
            {Services.map((item, index) => (
              <div key={index}
              ref={(el) => (serviceRefs.current[index] = el)}
                data-id={index}>
                {item.id % 2 === 0 ? (
                  <div dir="rtl" className="2xl:mr-[380px] xl:mr-[300px] -mt-8">
                    {/* <div className="text-[96px] font-bold text-start px-3" style={{ color: item.TextColor }}>0{item.id}</div> */}
                    <div  className="card-item w-[300px] h-[300px] 2xl:w-[380px] 2xl:h-[376px] rounded-[8px] p-4 flex flex-col gap-5 justify-center items-center" style={{ background: item.color }}>
                      <LazyImage src={item.icon} alt={item.icon} />
                      <p className="text-center flex justify-center items-center text-[18px] font-[400] text-[#182233]">
                        {item.description}
                      </p>

                    </div>
                    <div className="text-[96px] font-bold text-start -mr-[300px] -mt-28 mb-4 " style={{ color: Services[index + 1].TextColor }}>0{Services[index + 1].id}</div>
                  </div>

                ) : item.id !== 5 ? (
                  <div dir="rtl" className="-mt-8">
                    {/* <div className="text-[96px] font-bold text-start px-3" style={{ color: item.TextColor }}>0{item.id}</div> */}
                    <div  className="card-item w-[300px] h-[300px] 2xl:w-[380px] 2xl:h-[376px] rounded-[8px] p-4 flex flex-col gap-5 justify-center items-center" style={{ background: item.color }}>
                      <LazyImage src={item.icon} alt={item.icon} />
                      <p className="text-center flex justify-center items-center text-[18px] font-[400] text-[#182233]">
                        {item.description}
                      </p>

                    </div>
                    <div className="text-[96px] font-bold text-center mr-20 -mt-28 mb-4" style={{ color: Services[index + 1].TextColor }}>0{Services[index + 1].id}</div>
                  </div>
                ) : (
                  <div dir="rtl" className="mb-80  -mt-8">
                    {/* <div className="text-[96px] font-bold text-start px-3" style={{ color: item.TextColor }}>0{item.id}</div> */}
                    <div className="card-item w-[300px] h-[300px] 2xl:w-[380px] 2xl:h-[376px] rounded-[8px] p-4 flex flex-col gap-5 justify-center items-center" style={{ background: item.color }}>
                      <LazyImage src={item.icon} alt={item.icon} />
                      <p className="text-center flex justify-center items-center text-[18px] font-[400] text-[#182233]">
                        {item.description}
                      </p>

                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className="block xl:hidden w-full h-[100vh] bg-dark -mt-28 relative   lg:mb-40  " id="service-mobile" >
        <div style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          zIndex: '20000'
        }}><img onClick={nextSlide} src={activeIndex !== 4 && leftA} alt="" ></img></div>
        <div style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          zIndex: '20000'
        }}><img onClick={prevSlide} src={activeIndex !== 0 && rightA} alt="" ></img></div>
        <div className="w-[100%] h-[100vh] sticky flex justify-center items-center">
          <div className="z-[2] bg-services-2 blur-md absolute w-[100%] h-[100%]"></div>

          <div className="z-10 absolute flex flex-col justify-center items-center gap-3 w-[100%] h-[100%]">
            <div className="absolute z-20 flex justify-center items-center gap-3 flex-col w-[100%] h-[100%]">
              {/* <div className="w-[555px] h-[555px] border-[2px] border-[#a7a2a2] z-[2] absolute rounded-full mt-[2.8rem] shadow-2xl"></div> */}
              <Swiper
                dir="ltr"
                direction="horizontal"
                effect="creative"
                // grabCursor={true}
                slidesPerView={1}
                ref={swiperRef}
                edgeSwipeDetection="prevent"
                // reverseDirection={true}
                edgeSwipeThreshold={1}
                touchReleaseOnEdges={true}

                // mousewheel={true}
                //onSlideChange={handleSlideChange}
                //releaseOnEdges={true}
                mousewheel={{ forceToAxis: true }}
                onSlideChange={(e) => ChangeSlide(e)}
                style={{ height: "100vh", width: "100%", background: "transparent", zIndex: "-100 !important" }}
                id="swiper-mobile-services"
                // onReachEnd={EndSection}
                creativeEffect={{
                  prev: {
                    shadow: false,
                    translate: [0, 40, 0], // تنظیم مکان قبلی اسلاید
                    rotate: [0, 0, -180],
                  },
                  next: {
                    shadow: false,
                    translate: [0, 40, 0], // تنظیم مکان اسلاید بعدی
                    rotate: [0, 0, 180],
                  },
                }}
              >
                {Services.map((itemMobile, index) => (
                  <SwiperSlide

                    // ref={componentRef}
                    id="swiper-slide"
                    className="relative"
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >

                    <div className="absolute z-[1] w-[300px] h-[300px] flex justify-center items-center mt-10" style={{ background: `url(${itemMobile.background})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", backgroundPosition: "center" }}>
                      <div className="transition-33  w-[500px] z-[3] h-[100%] flex justify-center items-center">
                        <p className="text-center top-[-80px] absolute z-[30] hidden text-[20px] font-[700] ff-bold" style={{ color: itemMobile.TextColor }} id="text">WHAT WE DO</p>
                        <p className="text-center top-[-45px] absolute z-[30] hidden text-[20px] ff-[100] text-white" style={{
                          fontWeight: '400',
                          fontFamily: 'blinker'
                        }} id="text">{itemMobile.title}</p>
                        <div className="text-center top-[-12px] absolute z-[30] hidden text-[20px] font-[400] w-[60px] h-[2px] bg-white text-white" id="text"></div>
                        <div className="w-[136px] h-[136px] z-[3] rounded-full flex justify-center items-center text-[60px] font-[800] ff-bold pb-[30px]" id="circle" style={{ background: itemMobile.TextColor }}>
                          0{itemMobile.id}
                        </div>
                      </div>
                    </div>
                    <div className="absolute h-[40vh] w-[226px] top-[19%] flex flex-col gap-2 mt-[-55px] opacity-[13%] z-[30]" id="swiper-card">
                      <div style={{ background: itemMobile.color }} className="w-[226px] rounded-lg flex flex-col items-center justify-center gap-5 py-4">
                        <LazyImage src={itemMobile.icon} alt="icon" className="w-[85px] h-[75px] lg:w-auto lg:h-auto mt-4" />
                        <p className="text-center text-[#182233] text-[13px] font-[400] w-[85%] mb-4">
                          {itemMobile.description}
                        </p>
                      </div>
                      <Link className="w-[100%] py-3 rounded-lg text-center text-[#182233] text-[20px] font-[400] mt-2" style={{ background: itemMobile.TextColor }}>
                        Learn more
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}

              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[-25px]" id="next-section"></div>
    </section>
  );
};

export default SectionService
