import React from 'react';
import ServicesCustomButton from '../ServicesCustomButton';

export default function AdsEndSection() {
  return (
    <section className="relative flex h-[100vh] w-full items-center justify-center bg-slate-900 overflow-hidden">
      <div className="container flex flex-col items-center justify-center px-4 text-center z-10 ">
        <h6 className=" text-[48px] ff-thin tracking-tight text-white md:text-5xl lg:text-[96px] max-w-[290px] md:max-w-[900px]">
          {"Let's get your "}
          <span className="text-[#83E0F5]">brand</span>
          <br></br>{" where "}
          <span className="text-[#83E0F5]">you</span>
          {" want it to be"}
        </h6>
        <div className="my-8 mb-20 h-px w-1/2 bg-[#eee]" aria-hidden="true" />
        <ServicesCustomButton /> 
      </div>
      <div className="absolute bottom-0 left-0 w-[1500px] h-[1500px] bg-gradient-to-r from-[#83E0F5] to-[transparent] rounded-full filter blur-3xl opacity-20 -translate-x-1/2 translate-y-1/2" aria-hidden="true" />
    </section>
  );
}