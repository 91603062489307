import { Button } from '@mui/material'
import React from 'react'

export default function GetaProposalBtn({text}) {
  return (
    <Button variant='contained' href="/quote" sx={{
      fontFamily:'blinker',
      fontWeight:'400',

      textTransform:'none',
      
      fontSize:'16px' ,
      "@media (min-width: 1024px) and (max-width: 1280px)": { 
        fontSize: '9px',
        },
    }} className='btn-header xl:w-[142px] xl:h-[42px] sm:w-[90px] sm:h-[30px] w-[182px] h-[52px] bg-[#10F9B1] rounded-lg text-black mt-4 '>
      {text != null ? text : "Get a Proposal"} 
    </Button>
  )
}
