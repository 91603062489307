import React, { useRef, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import FreeConsultationBtn from "./FreeConsultationBtn";
// import { Player } from 'video-react';
// import ReactPlayer from 'react-player'

import 'mui-player/dist/mui-player.min.css'
import MuiPlayer from 'mui-player'

const FullscreenVideo = ({ videoUrl, poster }) => {
  const videoRef = useRef(null);

  const [progress, setProgress] = useState(0); // State to manage the progress percentage

  const handleTimeUpdate = (e) => {
    if (e.target) {
      console.log(e.target.currentTime)
      const currentTime = e.target.currentTime;
      const duration =  e.target.duration;
      const progressPercentage = (currentTime / duration) * 100;
      setProgress(progressPercentage);
    }
  };
  const handleEndVideo = (e) => {
    console.log(e)
    e.target.load()
    // e.loaded = false
  }
  const handleVideoClick = (e) => {
    // console.log(e)


    if(e.target.paused){
      console.log(e.target.play())

    }
    else{
      console.log(e.target.pause())
    }
  };

  return (
    <>
      <Box
        sx={{
          display:{lg : 'flex' , xs : 'none'},
          overflow: "hidden",
          height: { md: "100vh", xs: "100vh" },
          width: "95vw",
          // display: { md: "flex", xs: "flex" },
          alignItems: "center",
          justifyContent: "space-evenly",
        
          mb: { xs: 15, md: 0 },
          mx: { xl: "50px", md : '10px' },
          backgroundImage:''
        }}
      >
        <div className="">
          <div>
            <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "36px",
                fontWeight: "600",
                lineHeight: "10px",
              }}
            >
              From Creativity
            </Typography>
            <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "55px",
                fontWeight: "800",
              }}
            >
              To Results
            </Typography>

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "blinker",
                fontSize: {xl:"24px",lg: '19px'},
                fontWeight: "100",
                width: {xl:"267px",lg:'220px'}
              }}
            >
              Unleashing your brand's potential with innovative strategies and
              digital expertise
            </Typography>
          </div>
          <div className="mt-20">
            <FreeConsultationBtn />
          </div>
        </div>
        <div className="flex flex-col relative">
        <video
          // id={'videoPlayer'}
          
          src={'/video/ThirdSection.mp4'}
            // controls
            // width={875}
            // height={490}
          onClick={handleVideoClick}
          onEnded={handleEndVideo}
          onTimeUpdate={handleTimeUpdate}
          ref={videoRef}
          type="video/mp4"
          className="w-[875px] h-[490px] pt-0 videoSection"
          // style={{ display: "block" }}
              // options={{
              //   thumbnail:"/video/ThirdSection.png"
              // }}
          poster={'/video/ThirdSection.png'}
          /> 
          
          <div
          style={{
            position: 'absolute',
            // mt:20,
            bottom:'-10px',
            // left: 0,
            height: '1%',
            width: `${progress}%`,
            backgroundColor: '#10F9B1',
            borderRadius: '5px',
            transition: 'width 0.1s linear',
          }}
        ></div>
        </div>
        
      </Box>

      <Box
        sx={{
          display:{lg : 'none' , xs : 'flex'},
          overflow: "hidden",
          // height: { md: "100vh", xs: "100vh" },
          height: { lg: "0", xs: "80vh" },
          flexDirection:'column',
          width: "90%",
          // display: { md: "flex", xs: "flex" },
          alignItems: "center",
          justifyContent: "center",
        
          // mb: { xs: 15, md: 0 },
          mx: { xs: "20px" },
          backgroundImage:''
        }}
      >
        <div className="">
          <div>
            <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "36px",
                fontWeight: "600",
                lineHeight: "10px",
                mt:2
              }}
            >
              From Creativity
            </Typography>
            <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "55px",
                fontWeight: "800",
                mb:4
              }}
            >
              To Results
            </Typography>

            
          </div>
          
        </div>
        <div className="relative">
        <video
          src={videoUrl}
          //   controls
          preload="none"
          ref={videoRef}
          onClick={handleVideoClick}
          onEnded={handleEndVideo}
          onTimeUpdate={handleTimeUpdate}
          type="video/webm"
          style={{
            // width: "85%",
            height: "182px", // Maintain aspect ratio
            width: "324px",
            objectFit: "fill", // Prevent cropping of the video
            // boxShadow: "1px 1px 10px  #10F9B170", // Glow effect around the video
          }}
          className="videoSection"
          poster={poster}
        >
          {/* <source src=""></source> */}
        </video>
        <div
          style={{
            position: 'absolute',
            // mt:20,
            bottom:'-10px',
            // left: 0,
            height: '1%',
            width: `${progress}%`,
            backgroundColor: '#10F9B1',
            borderRadius: '5px',
            transition: 'width 0.1s linear',
          }}
        ></div>
        </div>
        <Typography
              sx={{
                color: "#fff",
                fontFamily: "blinker",
                fontSize: "19px",
                fontWeight: "100",
                width: "267px",
                mt:4
              }}
            >
              Unleashing your brand's potential with innovative strategies and
              digital expertise
            </Typography>
        <div className="mt-4">
            <FreeConsultationBtn />
          </div>
      </Box>
    </>
  );
};

export default FullscreenVideo;
