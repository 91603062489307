import { createTheme } from '@mui/material/styles';

const theme = createTheme ({
  palette: {
    primary: {
        main: '#10f9b1',
        
      },
  },
  components: {
        MuiButton: {
          styleOverrides: {
            root: {
    
              '&.Mui-disabled': {
                color: 'rgba(0, 0, 0, 0.5)', // your custom disabled color here
                backgroundColor: 'rgba(122, 125, 156, 1)', // Customize background if needed
              },
              
            },
          },
        },
        // Add other components if needed
      },
});

export default theme;