import React, { useEffect, useState, useRef } from "react";

const LazyImage = ({ src, alt, placeholder, clss, onLoad }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();
  // console.log(clss)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry], observer) => {
        if (entry.isIntersecting) {
          const img = imgRef.current;
          if (img && img.dataset.src) {
            img.src = img.dataset.src; // Load image
            img.onload = () => setLoaded(true); // Fade-in effect
            observer.unobserve(img);
          }
        }
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
    //   if (imgRef.current) observer.unobserve(imgRef.current);
    };
    
  }, []);

  return (

    <img
      ref={imgRef}
      data-src={src}
      alt={alt}
      onLoad={onLoad}
      className={`${clss} ${loaded ? "loaded" : ""}`}
      src={placeholder} // Low-quality placeholder
      style={{ transition: "opacity 0.5s ease", opacity: loaded ? 1 : 0 }}
    />
  );
};

export default LazyImage;
