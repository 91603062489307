import React from 'react';
import SectoinOne from './rux/sectionOne';
import SectoinTwo from './rux/sectionTwo';
import '../../Library/css/rux.css'
import { Box, Button} from '@mui/material';
import { TabTitle } from '../../utils/GeneralFunctions';
// import {Breadcrumbs} from '@mui/material/Breadcrumbs'
const RuxIndex = () => {
  TabTitle("Rux")
  return (
    <Box sx={{
      overflow:'hidden',
    }}>
      <div role="presentation">
      
    </div>
    <SectoinOne />
    <SectoinTwo />
    <Box sx={{
          display:'flex',
          // mx:{md : 85 , xs : ''},
          flexDirection:'row',
          justifyContent:'center',
          columnGap: '2vw',
        }}>
        <Button className="np-button" href="/case-study/dentist" variant="contained" >Previous</Button>
        <Button className="np-button" disabled href="/case-study/rux" >Next</Button>
        </Box>
    </Box>
  );
};

export default RuxIndex;
