import React from 'react'
import BrandingHeroSection from './BrandingHeroSection'
import BrandingEndSection from './BrandingEndSection'
import BrandingSectionTwo from './BrandingSection2'
import BrandingSectionThree from './BrandingSectionThree'
import BrandingSectionFour from './BrandingSectionFour'
import { TabTitle } from '../../utils/GeneralFunctions'


function BrandingDesignPage() {
  TabTitle("Branding")
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <BrandingHeroSection />
      <BrandingSectionTwo />
      <div className='relative seo-sec2-background-2 ' >
        <div className='sticky top-0'>
    <BrandingSectionThree />
    <BrandingSectionFour />
    </div></div>
    {/* <ContentSectionFive /> */}
      {/*
      
    <ContentSectionFour />
    

      
      <ContentEndSection /> */}
      <BrandingEndSection />
    </div>
  )
}

export default BrandingDesignPage
