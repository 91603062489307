import React from 'react';
import useMedia from '../../hooks/useMedia';


function ContentSectionFive() {

  const { xl, } = useMedia()
  return (
    <section className="py-16 md:py-32 flex justify-center items-center">
      <div className="max-w-[1440px]">
        <div className="flex xl:flex-row flex-col items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {xl ? <img

              src="/Services/content/sec5/image.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl xl:w-[546px] xl:h-[780px] lg:mr-[164px]"
            /> : <img

              src="/Services/content/sec5/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl xl:w-[400px] 2xl:w-[600px] xl:h-[650px] 2xl:h-[850px] h-[260px] w-[356px] "
            />}
          </div>

          <div className="lg:pt-[92px] px-[68px] pt-[55px] bg-[black]/15 rounded-3xl lg:w-[512px] lg:h-[512px] w-[300px] h-[410px] border border-[#47f790] text-left">
            <div className="mb-[38px] rounded-lg w-fit lg:flex lg:flex-row lg:items-center">
              <img src='/Services/content/sec5/note.png' alt='book' className='w-12 h-12 lg:mr-4 lg:mb-0 mb-4' />
              <h2 className="lg:w-[300px]  text-lg md:text-lg lg:text-3xl font-bold text-white mb-4">
              UX Writing for Advertisements
              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px] lg:text-lg text-xs text-left text-gray-300 leading-relaxed">
            Crafting persuasive messaging that enhances the user experience in marketing campaigns.  <br />
Effective UX writing is crucial for successful advertisements. Our team writes clear, concise, and compelling messages that guide users on their journey and boost conversion rates.


            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContentSectionFive;
