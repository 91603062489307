import React from 'react'
import AdsHeroSection from './AdsHeroSection'
import AdsSectionTwo from './AdsSectionTwo'
import AdsSectionThree from './AdsSectionThree'
import AdsSectionFour from './AdsSectionFour'
import AdsSectionFive from './AdsSectionFive'
import AdsEndSection from './AdsEndSection'
import { TabTitle } from '../../utils/GeneralFunctions'


function AdsPage() {
  TabTitle("Ads")
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <AdsHeroSection />
      <AdsSectionTwo />
      <div className='relative seo-sec2-background-2' >
        <div className='sticky top-0'>
      <AdsSectionThree />
      <AdsSectionFour />
      <AdsSectionFive />
      </div>
      </div>
      <AdsEndSection />
    </div>
  )
}

export default AdsPage
