import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const BreadcrumbsComp = () => {
  const location = useLocation();
  const paths = location.pathname.split('/').filter(Boolean);

  return (<>
  <div className='mt-20'></div>
  {/* {location.pathname} */}
  {(location.pathname !== '/' && location.pathname !== '/meeting' && location.pathname !== "/quote" && location.pathname !== '/technical-support' && location.pathname !== '/branding' && location.pathname !== '/web-design' && location.pathname !== '/ads'&& location.pathname !== '/content-creation'&& location.pathname !== '/seo') && 
    <nav  className="flex text-[#7A7D9C] w-[300px] custom-bread z-10 overflow-hidden" style={{
        fontFamily:'blinker',
        fontWeight:'400',
        color:'#7A7D9C'
    }}>
      <Link to="/" className="hover:underline text-[#7A7D9C]">Home</Link>
      {paths.map((path, index) => {
        path = path.replaceAll("-" , " ")
        const to = `/${paths.slice(0, index + 1).join('/')}`;
        return (
          <span key={to} className="mx-2 text-[#7A7D9C]">
            {'/'}
            <Link to={to} className="hover:underline ml-2 text-[#7A7D9C]">{path[0].toLocaleUpperCase() + path.substring(1) }</Link>
          </span>
        );
      })}
    </nav>
}</>
  );
};

export default BreadcrumbsComp;
