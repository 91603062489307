// src/App.js
import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, TextField, Typography } from '@mui/material';
import ExpandMore from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/Remove';
import axios from 'axios';
import { TabTitle } from '../utils/GeneralFunctions';
import TicketModal from './modalTest/ticketModal';

// import {styled} from '@mui/system'

const Ticket = () => {
  TabTitle("Technical Support")
  const [expanded, setExpanded] = useState(false);  // State to track whether the accordion is expanded or not

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false); // Toggle the expanded state for each panel
  };
  const faqs = [
    {
      question: "How can I get a free consultation?",
      answer: "To receive a free consultation, please contact us or submit your request through the ticket form. We will get back to you shortly."
    },
    {
      question: "How long does it typically take to complete a project?",
      answer: "The duration of a project depends on the type of services and your specific needs. After reviewing the project details, we will provide you with an estimated timeline."
    },
    {
      question: "Can I see examples of your previous work?",
      answer: "Yes, you can view our previous work samples in the 'Portfolio' section of our website. You can also request specific samples through a ticket."
    },
    {
      question: "How can I get pricing information for your services?",
      answer: "The cost of services varies depending on the type of project and your specific needs. After reviewing the project details, we will provide you with a price proposal."
    },
    {
      question: "Do you offer post-sale support services?",
      answer: "Yes, we provide post-sale support services including website maintenance and necessary updates."
    },
    {
      question: "Is there an option for installment payments?",
      answer: "Yes, we have various payment options available and can discuss installment payment terms with you."
    },
    {
      question: "How can I request changes to my project?",
      answer: "If you need changes, please submit a new ticket and explain the details of the requested changes. Our team will contact you shortly."
    },
    {
      question: "Do you work with small businesses as well?",
      answer: "Yes, we collaborate with both small and large businesses and tailor our services to meet the specific needs of each business."
    }
  ];

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  // const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openModal , setOpenModal] = useState(false)

  
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async () => {
    const newErrors = {};
    
    // Email Regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    // Phone Regex (Accepts numbers, spaces, dashes, parentheses)
    const phoneRegex = /^[0-9\s\-().]+$/;
    
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    if (!title) newErrors.title = 'Title is required';
    if (!description) newErrors.description = 'Description is required';
    
    if (phone && !phoneRegex.test(phone)) {
      newErrors.phone = 'Invalid input. Please enter a valid number.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      setErrors({})
      
       await axios.post(apiUrl+'/ticket/new', {
        title,
        description,
        phone,
        email
      }).then(x=> {
        setOpenModal(true)
      });
      // setMessage(response.data.code);
    } catch (error) {
      alert('Error submitting form');
    }
    setLoading(false);

    
  };
  return (
    <div className='ticket-back'>
      
      <Box className="mt-2 mb-20 " sx={{
        maxWidth: '1400px',
        mx: { lg: '40px', xs: '20px' },
        display: 'flex',
        flexDirection: { md: 'column', xs: 'column' },
        // alignItems: 'center',
        alignItems: { lg: 'flex-start', xs: 'center', },

      }}>
        <Typography variant="h4" component="h2" sx={{
          fontWeight: '800',
          fontFamily: 'blinker',
          textAlign: { lg: 'flex-start', xs: 'center', },
          fontSize: { md: '48px', xs: '24px', },

        }} >
          Submit Support Ticket
        </Typography>
        <Typography variant="h4" component="h2" sx={{
          // fontWeight: '50px !important',
          fontWeight: '100',
          opacity:"75%",
          width: { md: 'auto', xs: '220px' },
          fontSize: { md: '24px', xs: '15px', },
          fontFamily: 'blinker',
        }} >
          Please fill in the information below so we can assist you.
        </Typography>
        <Grid container sx={{
          display: 'flex',
          flexDirection: { lg: 'row', xs: 'column' },
          alignItems: { lg: 'flex-start', xs: 'center', },
          

        }} className='lg:mt-20 mt-0'>
          <Grid item >
            <Box sx={{
              width: { md: '530px', xs: '345px' },
              height: '650px',
              background: { md: 'rgba(255,255,255,0.1)', xs: '' },
              borderRadius: '20px',
              px: { md: '70px', xs: '20px' },
              py: '40px',
            }}>

              <TextField sx={{

              }} label='Phone Number'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone}></TextField>
              <TextField sx={{
                mt: '30px'
              }} label='Email' value={email}
                onChange={(e) => setEmail(e.target.value)}

                margin="normal"
                required
                error={!!errors.email}
                helperText={errors.email} fullWidth></TextField>
              <TextField sx={{
                mt: '30px'
              }} label='Ticket subject' value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.title}
                helperText={errors.title}></TextField>
              <TextField sx={{
                mt: '30px'
              }} label='Ticket description' multiline rows={4} value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.description}
                helperText={errors.description}></TextField>
              <Button disabled={loading} onClick={handleSubmit} variant='contained' fullWidth sx={{
                mt: '10px',
                height: '42px'
              }}>Submit Ticket</Button>
            </Box>
          </Grid>
          <Grid item >
            <Box className="dark-scrollbar" sx={{
              maxHeight: '650px',
              overflow: 'scroll',
              overflowX: 'hidden',
              mx: { md: 10, xs: 0 },
              my: { md: 0, xs: 5 },
              maxWidth: '600px',
              width: { md: '600px', xs: 'auto' },
              background: { md: 'rgba(255,255,255,0.1)', xs: '' },
              borderRadius: '20px',
              px: { md: '25px', xs: '' }, 
              py: '40px',
            }}>
              <Typography sx={{
                fontFamily: 'blinker',
                fontSize: '20px',
                fontWeight: '600',
                color: '#10F9B1',
                textAlign: 'left',
                mb: '20px',
              }}>Frequently Ask Questions</Typography>
              {faqs.map((faq, index) => (
                <Accordion key={index} className="customAccordion" expanded={expanded === index}  // Control which accordion is expanded
                  onChange={handleAccordionChange(index)}
                  sx={{
                    alignItems: 'flex-start',
                    borderRadius:'5px !important',
                    paddingLeft:"10px",

                  }}
                  >
                  <AccordionSummary
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      width: '100%',
                      flexDirection: 'row-reverse', // This will reverse the order, placing the icon on the left
                      '& .MuiAccordionSummary-content': {
                        // justifyContent: 'space-between',
                        // ml: '40px'
                        // Ensures content spacing is correct
                      },
                      // alignItems: 'center',
                      // backgroundColor:"red",
                      // justifyContent: 'flex-start',
                      // alignItems: { lg: 'flex-start', xs: 'center', },
                    }}
                    expandIcon={
                      expanded === index ? (
                        <ExpandLess sx={{ color: '#10F9B1' }} />  // Icon when expanded
                      ) : (
                        <ExpandMore sx={{ color: 'white', justifyContent: 'flex-start' }} />  // Icon when collapsed
                      )
                    }

                  >
                    <Typography sx={{
                      fontFamily: 'blinker',
                      fontSize: '18px',
                      // backgroundColor:"blue",
                      // justifyContent: 'flex-start',
                      // alignItems: { lg: 'flex-start', xs: 'center', },
                    }}
                    >{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{
                      fontFamily: 'blinker',
                      fontSize: '14px',
                      // backgroundColor:"red",
                      padding:"0 10px 10px 25px",
                    }} 
                    >{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}


            </Box></Grid>
        </Grid>

      </Box>
      <TicketModal openModal={openModal}/>
      </div>
  );
}
export default Ticket;