export default function WebSectionTwo() {
  return (
    <section className="w-full h-[105vh] bg-dark z-10 relative seo-sec2-background flex justify-end items-center">
      <div className="web-sec2-background-2 absolute z-20 w-full h-full " />
      <div className="w-[100%] max-w-[500px] flex flex-col items-center h-auto z-30  md:mr-19 lg:mr-20 xl:mr-44 rounded-lg">
        <div className="web-sec2-card w-[300px] flex flex-col justify-center lg:w-[585px] lg:h-[525px] p-6 lg:p-8 space-y-6 ">
          {/* Search icon and heading container */}
          <div className="xl:flex items-start gap-4 lg:mb-14">
            <img
              src="Services/webD/sec2/badge.png"
              className=" w-8 h-8 mt-[-2px] md:w-10 md:h-10 lg:w-12 lg:h-12 flex-shrink-0 lg:mr-0 lg:mb-0 mb-4 "
              alt="Search Icon"
            />
            <h2 className="w-[160px] lg:w-[300px]  text-xl md:text-xl lg:text-2xl font-semibold text-left text-[#DEE2E6] leading-tight">
            Creative Landing Page Design

            </h2>
          </div>
          
          {/* Description text */}
          <p className="text-sm md:text-base lg:text-lg text-[#DEE2E6] leading-relaxed text-left">
          Designing impactful landing pages that capture leads with precision.  
          Landing pages are critical for conversions. Our design approach focuses on both beauty and function to attract visitors and drive them to act.
          </p>
        </div>
      </div>
    </section>
  )
}