import React from 'react'
import Lottie from "react-lottie";
import ham from "../../lottie/ham";
import { Link } from 'react-router-dom';
export default function Footer1() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
    
        animationData: ham,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
  return (
    <div className='flex justify-center items-center  z-10 flex-col h-full'>
              <Lottie options={defaultOptions} width={300} height={464}></Lottie>
              <Link to="/" style={{
                marginTop:'40px'
              }}>
                    <img src='logo.png' className='lg:w-[250px] w-auto flex justify-center items-center' alt=''/>
                  </Link>
            </div>
  )
}
