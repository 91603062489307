import React from 'react';
// import './CircleLoader.css';
import Lottie from "react-lottie";
import ham from "../lottie/loading";

const defaultOptions = {
  loop: true,
  autoplay: true,
  
  animationData: ham,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const CircleLoader = ({ isVisible }) => {
  return (
    <div className={`loader ${isVisible ? 'visible' : 'hidden'}`}>
      <Lottie width={300} height={300} options={defaultOptions}></Lottie>
    </div>
  );
};

export default CircleLoader;
