import { useState, useEffect } from 'react';

const useMedia = () => {
  const [matches, setMatches] = useState({
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  });

  useEffect(() => {
    // Define your media query breakpoints inside useEffect
    const breakpoints = {
      sm: '(min-width: 640px)',
      md: '(min-width: 768px)',
      lg: '(min-width: 1024px)',
      xl: '(min-width: 1280px)',
      '2xl': '(min-width: 1536px)',
    };

    const mediaQueryLists = {};
    const keys = Object.keys(breakpoints);

    const updateMatches = () => {
      const updatedMatches = {};
      keys.forEach((key) => {
        mediaQueryLists[key] = window.matchMedia(breakpoints[key]);
        updatedMatches[key] = mediaQueryLists[key].matches;
      });
      setMatches(updatedMatches);
    };

    updateMatches();

    keys.forEach((key) => {
      mediaQueryLists[key].addListener(updateMatches);
    });

    return () => {
      keys.forEach((key) => {
        mediaQueryLists[key].removeListener(updateMatches);
      });
    };
  }, []); // No need to include `breakpoints` in dependencies

  return matches;
};

export default useMedia;
