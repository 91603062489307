import { Button } from '@mui/material'
import React from 'react'

export default function GetaProposalBtnPurple({text}) {
  return (
    <Button variant='contained'  href="/quote" sx={{
      fontFamily:'blinker',
      fontWeight:'400',
      backgroundColor:'#AD68F2',
      textTransform:'none',
      fontSize:{lg:'16px' , xs:'16px' }
    }} className='btn-header-purple  sm:w-[142px] sm:h-[42px] w-[182px] h-[52px] bg-[#AD68F2] rounded-lg  mt-4 '>
      {text != null ? text : "Get a Proposal"} 
    </Button>
  )
}
