import React from 'react'
import WebHeroSection from './WebHeroSection'
import WebSectionTwo from './WebSectionTwo'
import WebSectionThree from './WebSectionThree'
import WebSectionFour from './WebSectionFour'
import WebEndSection from './WebEndSection'
import { TabTitle } from '../../utils/GeneralFunctions'


function WebDesignPage() {
  TabTitle("Web Design")
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <WebHeroSection />
      <WebSectionTwo />
      <div className='relative seo-sec2-background-2 ' >
        <div className='sticky top-0'>
    <WebSectionThree />
    <WebSectionFour />

      </div></div>
      <WebEndSection />
    </div>
  )
}

export default WebDesignPage
