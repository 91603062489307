import LazyImage from "../lazyImage";

export default function AdsSectionTwo() {
  return (
    <section className="w-full h-auto ads-sec1-background seo-sec2-background-2 lg:h-[105vh] bg-dark z-10  relative  flex lg:flex-row flex-col lg:justify-center items-center">
      <div className=" absolute h-[120vh] seo-sec2-background-2 lg:h-full z-20 w-full " />
      <div className="flex lg:flex-row flex-col  lg:w-[60%]  z-30  lg:max-w-[1440px] rounded-lg lg:items-end  mb-10">
        <div className="ads-sec2-card lg:mb-0 lg:mt-0 mb-40 mt-40 space-y-6 lg:h-[590px] lg:w-[400px] lg:mr-[25px] w-[300px] h-auto">
          {/* Search icon and heading container */}
          <div className="lg:flex lg:items-center gap-4 ">
            <LazyImage
              src="Services/ads/sec2/gads.png"
              className="w-8 h-8 md:w-10 md:h-10  lg:w-12 lg:h-12 flex-shrink-0 lg:mb-0 mb-4"
              alt="Search Icon"
            />
            <h2 className="text-lg md:text-xl lg:text-2xl font-semibold text-[#DEE2E6] leading-tight text-left">
            Google Ads  
            </h2>
          </div>
          
          {/* Description text */}
          <p className="text-sm md:text-base lg:text-lg  text-left text-[#DEE2E6] leading-relaxed">
          Development of targeted ad campaigns on platforms like Facebook, Instagram, LinkedIn, and Twitter. This includes audience segmentation, creative design, ad placement, and ongoing optimization based on engagement metrics.

          </p>
        </div>
        <div className="ads-sec2-card  space-y-6  lg:w-[696px] w-[300px] h-auto">
          {/* Search icon and heading container */}
          <div className="lg:flex items-center gap-4">
            <LazyImage
              src="Services/ads/sec2/sads.png"
              className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 flex-shrink-0 lg:mb-0 mb-4"
              alt="Search Icon"
            />
            <h2 className="text-lg md:text-xl lg:text-2xl font-semibold text-[#DEE2E6] leading-tight text-left">
            Social Media  Ads  
            </h2>
          </div>
          
          {/* Description text */}
          <p className="text-sm md:text-base lg:text-lg  text-left text-[#DEE2E6] leading-relaxed">
          Development of targeted ad campaigns on platforms like Facebook, Instagram, LinkedIn, and Twitter. This includes audience segmentation, creative design, ad placement, and ongoing optimization based on engagement metrics.

          </p>
        </div>
      </div>
    </section>
  )
}