import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
// import myImg from "/Services/sec3/image.png"
function BrandingSectionThree() {

  const { xl, } = useMedia()

  return (
    <section className="py-16 md:py-32 flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex xl:flex-row flex-col items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {xl ? <LazyImage

              src="/Services/brand/sec3/image.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl xl:w-[546px] xl:h-[780px] lg:mr-[164px]"
            /> : <LazyImage

              src="/Services/brand/sec3/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl  h-[260px] w-[356px] "
            />}

          </div>

          <div className="lg:pt-[92px] px-[68px] pt-[55px] bg-[black]/15 rounded-3xl lg:w-[512px] lg:h-[512px] w-[300px]  brand-card">
            <div className="mb-[38px] rounded-lg w-fit lg:flex lg:flex-row items-center">
              <LazyImage src='/Services/brand/sec3/book.png' alt='book' className='w-8 h-8 lg:w-12 lg:h-12 mr-5 lg:mb-0 mb-4' />
              <h2 className=" lg:w-[325px] w-[211px] text-xl md:text-xl lg:text-3xl font-bold text-white mb-4 text-left lg:mt-10">
                Pitch Deck Design

              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px] lg:text-lg text-xs text-left text-gray-300 leading-relaxed">
              Creating engaging presentations that effectively communicate your brand's value proposition. <br />
              Use a pitch deck designed by professionals to create a memorable pitch. We aim for clarity, visual appeal, and persuasion. We want people to hear and remember your message.



            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BrandingSectionThree;
