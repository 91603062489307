import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
function WebSectionFour() {
  const {  xl } = useMedia()
  return (
    <section className="py-16 md:py-32  flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex xl:flex-row-reverse  flex-col  gap-8 items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {xl ? <LazyImage

              src="/Services/webD/sec4/image.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl xl:w-[546px] xl:h-[780px] lg:ml-[164px]"
            /> : <LazyImage

              src="/Services/webD/sec4/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl xl:w-[400px] 2xl:w-[600px] xl:h-[650px] 2xl:h-[850px] h-[260px] w-[356px] "
            />}
          </div>

          <div className=" lg:pt-[92px] px-[68px] pt-[45px] bg-[black]/15 rounded-3xl lg:w-[512px] lg:h-[512px] w-[300px]  web-sec2-card text-left">
            <div className="mb-[38px] rounded-lg  w-fit lg:flex lg:flex-row lg:items-center">
              <img src='/Services/webD/sec4/badge.png' alt='book' className='w-12 h-12 lg:mr-4 lg:mb-0 mb-4' />
              <h2 className="lg:w-[325px] w-[211px] text-[21px] md:text-xl lg:text-3xl font-bold text-white mb-4 text-left">
              WordPress Website Development

              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px] lg:text-lg text-xs text-left text-gray-300 leading-relaxed">
            Creating effective and visually appealing websites with WordPress.  <br></br>
We use WordPress to create responsive, user-friendly websites that are easy to manage and customize. Enjoy a seamless experience from design to launch and beyond.


            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebSectionFour;
