import { Box, Button,Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { ScheduleMeeting } from 'react-schedule-meeting';
import { timeSlotDifference } from 'react-schedule-meeting';
import { TabTitle } from '../../utils/GeneralFunctions';
import svg from '../SVG/discord-icon.svg';
import { Alert } from '@mui/material';
import CircleLoader from '../circleLoader';
import CalendarModal from '../modalTest/calendarModal';

export default function MeetingCalendar({ onTimeSelect , onTimeSelectModal }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [time] = useState(null); // Time selected by the user
    const [availableTimeslots, setAvailableTimes] = useState([]);
    
    const getData = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/meeting?time=${time}`);
            const Times = timeSlotDifference(response.data.time, response.data.unavalible)
            setAvailableTimes(Times);

        } catch (error) {
            console.error('Error fetching meeting times:', error);
        }
    }, [apiUrl, time]);

    useEffect(() => {
        getData();
    }, [getData]);

    TabTitle("Meeting")

    
    const sleep = ms => new Promise(r => setTimeout(r, ms));
    let topDateContent1 = "Today"
    let [topDateContent, setTopDateContent] = useState(topDateContent1);

    const updateContent = async() => {
        const elements = document.querySelectorAll('.go4114704207.rsm-date-title');
        await sleep(10)
        if (elements.length > 0) {
            const contents = Array.from(elements).map(element => element.textContent);
            const element = Array.from(document.querySelectorAll('.go4114704207.rsm-date-title'));

            if (window.innerWidth >= 760 && element.length > 1) {
                element[1].style.display = 'none';
            }
            if (contents[1]) {
                setTopDateContent(contents[1]);
            }
        }
    };

    // const selectedTime = (e) => {
    //     const formattedDate = new Date(e.startTime);
    //     if (onTimeSelect) {
    //         onTimeSelect(formattedDate);
    //     }
    // };
    useEffect(() => {
        updateContent();

    }, [updateContent]);

    const [selectedUserTime, setSelectedUserTime] = useState('')
    const [showAlert, setShowAlert] = useState(false);
    const [combinedDateTime, setCombinedDateTime] = useState('');
    
        // const handleButtonClick = () => {        
        //     console.log('selectedUserTime',selectedUserTime);
        //     if(selectedUserTime == ''){
        //     //     setIsFormVisible(true);
        //     // }else{
        //         setShowAlert(true);
        //         setTimeout(() => setShowAlert(false), 3000);        
        //     } 
        // };
        const [formattedDate, setFormattedDate] = useState('')

        const selectedTime = (e) => {
            const formattedDate = new Date(e.startTime);
            
            const dateObject = e.startTime;
            const formattedDate5 = dateObject.toISOString().split('T')[0];
            const formattedTime5 = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            const combinedDateTime = `${formattedDate5} at ${formattedTime5}`;
            setCombinedDateTime(combinedDateTime);
            
            setFormattedDate(formattedDate);
        };
        const handleButtonClick = (e) => {
            if (onTimeSelect) {
                onTimeSelect(formattedDate);
                // onTimeSelectModal(combinedDateTime);
            }
            if (onTimeSelectModal) {
                onTimeSelectModal(combinedDateTime);
            }
            if(formattedDate == ''){
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);        
            } 
        };

    
    return (
        // availableTimeslots.length === 0 ? <CircleLoader isVisible={true}></CircleLoader> : 
        <div className=" flex flex-col align-center justify-center items-center ">
        <div className="relative w-full mr-0 sm:mr-8 hidden lg:flex justify-center">
        {/* <Box className="rounded-lg border-[1px] text-left border-solid border-[#10F9B1] sm:w-[93%] md:w-[84%] lg:w-[82%] xl:w-[85%] 2xl:w-[87%]  flex flex-row align-center justify-center items-center ml-[0vw] sm:ml-5 md:ml-0 h-[50px]" id="uui5" > */}
        <Box className="rounded-lg border-[1px] text-left border-solid border-[#10F9B1]  flex flex-row align-center justify-center items-center  h-[50px]" id="uui5" >
        <Typography
            sx={{
                fontFamily: 'Blinker',
                fontSize: '14px',
                fontWeight: '300',
                position:'absolute',
                left:"50px",
                top:'-30px',
            }}
        >
            Select date and time
        </Typography>
        <Typography
            sx={{
                fontFamily: 'Blinker',
                fontSize: '16px',
                fontWeight: '300',
            }}
            className=" absolute left-20"
        >
            {topDateContent}
        </Typography>
        </Box>
        <img
            src={svg}
            alt="Logo"
            // className="absolute mt-0 -bottom-2 -right-0 md:right-40 h-16 w-16 lg:h-20 lg:w-20"
            className="absolute mt-0 -bottom-2 right-0 h-16 w-16 lg:h-20 lg:w-20" id="uui6"
        />
        {showAlert && (
                                        <Alert 
                                        severity="warning"
                                        sx={{
                                            backgroundColor: 'transparent',
                                            color: '#ffffff',
                                            fontSize: '14px',
                                            border: '1px solid #10F9B1',
                                            borderRadius: '8px',
                                            padding: '8px 15px',
                                            fontFamily: 'Blinker',
                                            position:'absolute',
                                            top:"520px",
                                            // left:{ md:"0px", lg:"0px"},
                                            // top:"520px",
                                            // left:"-50px",
                                            "@media (min-width: 1024px) and (max-width: 1439px)":{
                                                left:"47px",
                                                padding: '5px 13px',
                                                },
                                            "@media (min-width: 1440px)": { 
                                                left:"50px",
                                                fontSize: '16px',
                                                },
                                        }}
                                        >
                                            Specifying a time for the meeting is mandatory.
                                        </Alert>
                                    )}
                                
                                <div className='lg:flex lg:flex-row-reverse lg:w-[70vw] absolute ' id="uui7">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className=" text-white  font-bold lg:w-1/4 w-[70vw] "
                                    onClick={handleButtonClick}
                                    sx={{
                                        maxWidth: '200px',
                                      }}
                                >
                                    confirm
                                </Button>
                                </div>
        
        </div>

        <div>
        
        <Box onClick={() => updateContent()} >
            <div className="relative flex flex-col items-center justify-center lg:hidden " id="logotopmeet">
                <img
                    src={svg}
                    alt="Logo"
                    className=" flex justify-center items-center align-center h-16 w-16 m-4 "
                />
                <Box className="rounded-lg border-[1px] border-solid border-[#10F9B1] flex align-center justify-center items-center h-[50px] w-[93%] text-[16px]" id="logotopmeet2" >
                    {topDateContent}
                </Box>
                {showAlert && (
                                        <Alert 
                                        severity="warning"
                                        sx={{
                                            backgroundColor: 'transparent',
                                            color: '#ffffff',
                                            fontSize: '13px',
                                            border: '1px solid #10F9B1',
                                            borderRadius: '8px',
                                            padding: '5px 5px',
                                            fontFamily: 'Blinker',
                                            position:'absolute',
                                            top:"850px",
                                            left:"22px",
                                        }}
                                        >
                                            Specifying a time for the meeting is mandatory.
                                        </Alert>
                                    )}
                                
                                <div className='lg:flex lg:flex-row-reverse lg:w-[70vw] absolute ' id="uui8">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className=" text-white  font-bold  w-[70vw] "
                                    onClick={handleButtonClick}
                                    sx={{
                                        maxWidth: '300px',
                                      }}
                                >
                                    confirm
                                </Button>
                                </div>
            </div>
            {availableTimeslots.length > 0 && (
                <ScheduleMeeting
                skipConfirmCheck={true}
                borderRadius={10}
                primaryColor="#10F9B1"
                eventDurationInMinutes={30}
                availableTimeslots={availableTimeslots}
                onStartTimeSelect={selectedTime}
                backgroundColor="transparent"
                startTimeListStyle="scroll-list"
                />
             )} 
            
            {/* {showAlert && (
                                        <Alert 
                                        severity="warning"
                                        sx={{
                                            backgroundColor: 'transparent',
                                            color: '#ffffff',
                                            fontSize: '18px',
                                            border: '1px solid #10F9B1',
                                            borderRadius: '8px',
                                            padding: '10px 20px',
                                            marginRight: '5%',
                                        }}
                                        >
                                            Specifying a time for the meeting is mandatory.
                                        </Alert>
                                    )}
                                <div className='lg:flex lg:flex-row-reverse lg:w-[70vw] absolute ' id="uui7">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className=" text-white  font-bold lg:w-1/4  w-[70vw] "
                                    onClick={handleButtonClick}
                                    sx={{
                                        maxWidth: '200px',
                                        // marginRighta:'50px',
        
                                      }}
                                >
                                    confirm
                                </Button>
                                </div> */}
        </Box>
        
        </div>

        </div>
    );
}
