import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';


function SeoSectionFive() {

  const { xl,  } = useMedia()
  return (
    <section className="py-16 md:py-32 flex justify-center items-center">
      <div className="max-w-[1440px]">
        <div className="flex xl:flex-row flex-col items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {xl ? <LazyImage

              src="/Services/sec5/image.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl xl:w-[546px] xl:h-[780px] lg:mr-[164px]"
            /> : <LazyImage

              src="/Services/sec5/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl xl:w-[400px] 2xl:w-[600px] xl:h-[650px] 2xl:h-[850px] h-[260px] w-[356px] "
            />}
          </div>

          <div className="lg:pt-[92px] px-[68px] pt-[55px] bg-[black]/15 rounded-3xl lg:w-[512px] lg:h-[512px] w-[300px] h-[410px] border border-[#47f790] text-left">
            <div className="mb-[38px] rounded-lg w-fit lg:flex lg:flex-row lg:items-center">
              <LazyImage src='/Services/sec5/badge.png' alt='book' clss='w-9 h-9 md:w-14 h-14  lg:mb-2 mb-4' />
              <h2 className="lg:w-[300px]  text-lg md:text-lg lg:text-3xl font-bold text-white mb-4 md:ml-6">
                Competitor and Market SEO Analysis
              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px] lg:text-lg text-xs text-left text-gray-300 leading-relaxed">
              Analyzing competitor strategies and market trends for improved SEO performance.<br />
              Understanding your competition is crucial for SEO success. Our comprehensive analysis provides insights into competitor strategies and market trends, helping you identify opportunities for growth.

            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SeoSectionFive;
