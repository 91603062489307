import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {  useParams } from 'react-router-dom';
import {  Typography, Card, CardContent, CardMedia, Container } from '@mui/material';
import { styled } from '@mui/system';
import '../Library/css/ck.css';
import parse from 'react-html-parser'
import { TabTitle } from '../utils/GeneralFunctions';
const StyledTypography = styled(Typography)`
  img {
  border-radius : 12px
    // height: auto;
    // width:100% !important
  }


  p span *{
    font-family : Blinker;
    font-weight : 600

  }
`;


const StyledCard = styled(Card)`

`;
const apiUrl = process.env.REACT_APP_API_URL;
const PostDetails = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    axios.get(apiUrl + `/post?category=blogs&id=${id}`)
      .then(response => {
        setPost(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the post!', error);
      });
  }, [id]);

  if (!post) {
    return <Typography>Loading...</Typography>;
  }

  const content = post.content;
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const images = doc.querySelectorAll('img');
  TabTitle(post.title)
  return (
    <>
    <div role="presentation">
      
    </div>
    <Container sx={{
      mt:{xs:'60px', md : '80px'}
    }}>
      <StyledCard sx={{
      backgroundColor:'transparent !important'
    }}>
        {images.length > 0 && (
          <CardMedia
            component="img"
           
            image={post.banner}
            
            alt={post.title}
            sx={{
              
              borderRadius: '12px',
              display: 'block',
              marginTop: {xs:"75px" ,md:0},
              maxHeight:'400px',
            }}
          />
        )}
        <CardContent>
          <Typography className='ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred' variant="h4" sx={{
            color:'white',
            fontSize:{md : '28px' , xs : '16px' },
          }}  mb={3} align="center">
            {post.title}
          </Typography>
          <StyledTypography
          id='postDetails'
          className='ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred'
          //  sx={{
          //   display:'inline',
          //   width:"100%",
          //   flexDirection:'column',
          //   alignItems:'center',
          //   alignContent:'center'
          // }}
          //   variant="body2"
          //   component="div"
          //   dangerouslySetInnerHTML={{ __html: content }}
          >
            {parse(content)}
            </StyledTypography>
        </CardContent>
      </StyledCard>
    </Container>
    </>
  );
};

export default PostDetails;
