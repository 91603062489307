// faqData.js
export const faqData = [
    {
      id: 1,
      tabTitle: 'About',
      faqs: [
        {
          question: 'Why Beverly?',
          answer: 'At Beverly, creativity and transparency are the foundations of everything we do. Our path has included exciting projects and countless opportunities to add real value. As a budget friendly digital marketing business, we like selecting projects that not only ignite our passion but also leverage our extensive experience for the best impact.',
        },
        {
          question: `How can I submit feedback and keep the Beverly team updated on my project's progress?`,
          answer: 'By sharing your feedback with your dedicated account manage. You will receive frequent updates on the status of your project and have the opportunity to participate in demo sessions. We keep you updated every step of the journey!',
        },
        {
            question: `Why can Beverly's audit of digital marketing services be relied on for productive strategies?`,
            answer: `Beverly's marketing audit services combine professionalism and flexibility, catering to any budget. Beverly’s personalized, high-quality solutions provide demonstrable business outcomes and gain clients' confidence. We drive success with customized methods that enhance ROI. It applies to undertakings of any size or scope.`,
        },
      ],
    },
    {
      id: 2,
      tabTitle: 'Branding',
      faqs: [
        
        {
            question: `What is Brand Identity?`,
            answer: `Brand identity refers to the visual and verbal components of a brand. It uses them to communicate the brand's values and personality to its target audience. They include the logo, color scheme, typography, message, and design.`,
        },
        {
            question: `How does branding help businesses?`,
            answer: `Branding sets your business apart, builds trust, and makes it memorable. Beverly develops brand identities, logos, and websites, ensuring your brand stands out and is consistent across all channels.`,
        },
        {
            question: `How much does branding cost?`,
            answer: `Branding costs can differ approximately. They depend on the project's scope, the designer's experience, and their location. Businesses should assess their needs and budget to brand effectively.`,
        },
        {
            question: `How long does it take to create a brand identity?`,
            answer: `It depends on the project's scope, design complexity, and revisions. It takes 4 to 8 weeks on average to ensure quality and consistency.`,
        },
        {
            question: `How will branding impact business growth in Canada in 2025?`,
            answer: `In 2025, effective branding will be vital for growth. It establishes your business's identity, builds customer trust, and differentiates you from competitors. A strong brand is essential for attracting customers and boosting sales in a crowded market.`,
        },
      ],
    },
    {
        id: 3,
        tabTitle: 'Content',
        faqs: [
        {
            question: `What types of content should I create for my website and social media?`,
            answer: `·Website Content: Blog posts: Articles addressing audience questions. Product pages: Detailed descriptions of offerings. Landing pages: Single-page sites designed to capture leads. Case studies: Examples showcasing customer success stories. Videos: Engaging visual content for education or promotion.
                    ·Social Media Content: Posts: Engaging updates that resonate with your audience. Stories/Reels: Short, captivating videos or images that promote interaction. Infographics: Graphics that are visually appealing and convey information clearly. User-generated content: Sharing content created by customers or followers to build community.
                    ·Advertisements: Display Ads: Visual ads placed on websites to attract attention. Social media platforms like Facebook, Instagram, and LinkedIn display these targeted ads. They aim to reach specific audiences. Video Ads: Short videos for platforms like YouTube and social media. Motion Graphics Ads: Engaging animated visuals made to convey messages. They are often used in digital marketing on various platforms`,
        },
        {
            question: `Why should I usually update my website and social media?`,
            answer: `Update your website with 1 to 2 new articles each month. Also, publish three to five times every week on social media. It keeps your audience engaged, improves SEO, and strengthens your brand. It ensures you stay relevant in a competitive market`,
        },
        {
            question: `Why is Beverly the best choice for content creation?`,
            answer: `Beverly is the best choice for content creation. We deliver high-quality content at competitive prices. Our team creates tailored messages. They engage your audience and build trust. You get great value, and everything is within budget.`,
        },
        ],
      },
      {
          id: 4,
          tabTitle: 'SEO',
          faqs: [
        {
            question: `What exactly is SEO, and why does my business need it?`,
            answer: `SEO (Search Engine Optimization) boosts your site's visibility on search engines like Google. It helps people find your business online. It is key for those searching for products or services like yours.`,
        },
        {
            question: `Can SEO benefit my business if I have a limited budget?`,
            answer: `Absolutely! SEO can benefit businesses of all sizes, even with a limited budget. It boosts your online visibility and attracts targeted traffic. It helps you compete with larger firms by targeting niche and local searches. Investing in SEO is a smart way to enhance your brand's presence and drive growth.`,
        },
        {
            question: `How does Beverly help small businesses with SEO?`,
            answer: `We customize our SEO strategies to your needs and budget. It ensures that even small businesses can grow. We optimize for local search. It helps you reach nearby customers better.`,
        },
        {
            question: `How long does SEO take to show results with Beverly's services?`,
            answer: `SEO takes time to build momentum. But, with Beverly's expert strategies, you can see improvements in 3 to 6 months. The difficulty of keywords and competition can influence this timeline. If you need a strong digital presence in under three months, consider Google Ads. Consistency is key for lasting results and maximizing your online visibility.`,
        },
        ],
    },
    {
            id: 5,
            tabTitle: 'ADS',
            faqs: [
        {
            question: `What are online ads, and how do they help my business?`,
            answer: `Online ads are targeted messages shown to potential customers on the web. We monitor clicks and views to ensure that initiatives succeed. Continuous optimization is critical to achieving the greatest outcomes.`,
        },
        {
            question: `How does Beverly create targeted advertising campaigns within my budget?`,
            answer: `Good advertisements don't need a huge budget. Beverly uses step by step, measurable and eye-catching advertisements to reach your target audience. With our expertise, Beverly is perfect for tight budgets and will ensure success at every step.`,
        },
        {
            question: `How do I get updates on my campaign?`,
            answer: `You'll have an account manager who updates you. Beverly sends regular reports on ad views, clicks, and actions. These include website visits and purchases.`,
        },
        {
            question: `What can local businesses gain from Beverly's local ads?`,
            answer: `Beverly's local ads connect businesses with their community. They target specific groups and areas, increasing visibility and foot traffic. It boosts brand recognition, sales, and loyalty.`,
        },
        {
            question: `Why are Beverly's ads so effective, and when do I see results?`,
            answer: `Beverly's ads engage targeted audiences, showing quick results. You might see outcomes within days. We monitor clicks and views to ensure that initiatives succeed. Continuous optimization is critical to achieving the greatest outcomes.`,
        },
        ],
    },
    {
        id: 6,
        tabTitle: 'Web Design',
        faqs: [
        {
            question: `Custom Websites vs. Template Websites: Which One Suits Your Needs?`,
            answer: `It depends on your goals. Do you need a custom site or a template? Templates are cheap and fast. Custom sites provide unique branding and features. Choose based on your timeline, budget, and desired level of customization.`,
        },
        {
            question: `How much time should I expect for a full website design or redesign?`,
            answer: `Our web and app projects usually take 4 to 12 weeks, depending on their complexity and scale. Each project is carefully crafted to meet your specific needs and objectives.`,
        },
        {
            question: `Why is good web design important?`,
            answer: `Good web design is essential for attracting and keeping users. It has an appealing homepage, easy navigation, and fast load times. It creates an engaging experience. A well-designed site encourages users to explore further and enhances overall satisfaction.`,
        },
        {
            question: `What types of websites or applications can I get within my budget?`,
            answer: `We create custom websites and apps. They range from simple landing pages to complex e-commerce sites and web apps. Beverly’s packages start at $500 and go up depending on the scope and features required.`,
        },
        {
            question: `How long does it take to create a custom landing page for a campaign or event?`,
            answer: `It takes 1 to 3 weeks to design and evolve a custom landing page. It's essential for capturing attention and driving conversions.`,
        },
        {
            question: `What's the best solution for getting a website on a limited budget?`,
            answer: `Beverly's starter package will help you create a low-cost, professional website. It will kickstart your online presence. It has all the necessary design, development, and mobile friendly features. It's everything to launch your online business on a budget.`,
        },
        {
            question: `What makes Beverly's web design services unique?`,
            answer: `Beverly offers custom, high-quality web designs that showcase your brand. We create user friendly websites that look great and are SEO optimized. It helps you stand out and rank better in search results.`,
        },
        ],
    },
  ];
  