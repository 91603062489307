export default function SeoSectionTwo() {
  return (
    <section className="w-full h-[105vh] bg-dark z-10 relative seo-sec2-background flex justify-end items-center">
      <div className="seo-sec2-background-2 absolute z-20 w-full h-full " />
      <div className="w-[100%] max-w-[500px]  h-auto z-30 mx-3 md:mr-19 lg:mr-20 xl:mr-44 rounded-lg">
        <div className="seo-sec2-card h-full p-6 lg:p-8 space-y-6 ">
          {/* Search icon and heading container */}
          <div className="lg:flex items-center gap-4">
            <img
              src="Services/seo-sec2-search.png"
              className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 flex-shrink-0 lg:mb-0 mb-4"
              alt="Search Icon"
            />
            <h2 className="text-lg md:text-xl lg:text-2xl font-semibold text-[#DEE2E6] leading-tight text-left">
              Keyword Research and Analysis
            </h2>
          </div>
          
          {/* Description text */}
          <p className="text-sm md:text-base lg:text-lg text-[#DEE2E6] leading-relaxed text-left">
            Identifying optimal keywords to target for your business. Effective
            SEO starts with understanding what your audience is searching for.
            We find high-impact keywords that can boost your site's traffic,
            increase its visibility, and promote growth.
          </p>
        </div>
      </div>
    </section>
  )
}