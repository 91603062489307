import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ghost from './ghost.png';

const NotFoundPage = () => {
  return (
  
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0f172a",
        color: "#ffffff",
        textAlign: "center",
      }}
      
    ><div className="header-image2"></div>
      {/* Ghost Icon */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "80px",
              lg: "150px",
            },
            fontWeight: "bold",
            fontFamily: "Blinker",
            textShadow: {
              xs: `
              -9px -9px 0 #000, 
              9px -9px 0 #000, 
              -9px 9px 0 #000, 
              9px 9px 0 #000,
              0px -9px 0 #000, 
              0px 9px 0 #000, 
              -9px 0px 0 #000, 
              9px 0px 0 #000
            `,
              lg: `
              -17px -17px 0 #000, 
              17px -17px 0 #000, 
              -17px 17px 0 #000, 
              17px 17px 0 #000,
              0px -17px 0 #000, 
              0px 17px 0 #000, 
              -17px 0px 0 #000, 
              17px 0px 0 #000
            `,},
          }}
        >
          4
        </Typography>
        <Box
          component="img"
          src={ghost}
          alt="Ghost Icon"
          sx={{ width: "8vw", height: "11vw" ,
                minWidth: "90px",
                minHeight: "120px",
          }}
        />
        <Typography
          sx={{
            fontSize: {
              xs: "80px",
              lg: "150px",
            },
            fontWeight: "bold",
            fontFamily: "Blinker",
            textShadow: {
              xs: `
              -9px -9px 0 #000, 
              9px -9px 0 #000, 
              -9px 9px 0 #000, 
              9px 9px 0 #000,
              0px -9px 0 #000, 
              0px 9px 0 #000, 
              -9px 0px 0 #000, 
              9px 0px 0 #000
            `,
              lg: `
              -17px -17px 0 #000, 
              17px -17px 0 #000, 
              -17px 17px 0 #000, 
              17px 17px 0 #000,
              0px -17px 0 #000, 
              0px 17px 0 #000, 
              -17px 0px 0 #000, 
              17px 0px 0 #000
            `,},
          }}
        >
          4
        </Typography>
      </Box>

      {/* Text */}
      <Typography
        sx={{
          fontSize: {
            xs: "30px",
            lg: "50px",
          },
          fontWeight: "bold",
          fontFamily: "Blinker",
          mb: 1,
        }}
      >
        Boo! Page missing!
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "19px",
            lg: "20px",
          },
          color: "#9ca3af",
          mb: 4,
          fontFamily: "Blinker",
          padding:'0 20vw !important',
        }}
      >
        We are unable to find the page you are looking for
      </Typography>

      {/* Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#54F77E",
          color: "#000000",
          textTransform: "none",
          fontFamily: "Blinker",
          fontSize: {
            xs: "15px",
            lg: "21px",
          },
          padding: "10px 20px",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#16a34a",
          },
        }}
        href="/"
      >
        Go back home
      </Button>
    </Box>
  );
};

export default NotFoundPage;
