import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import hero1 from "./assets/hero1.png";
import hero2 from "./assets/hero2.png";
import hero3 from "./assets/hero3.png";
const Image = styled("img")({
  maxWidth: "100%",
  height: "auto",
});

function DentistHeroSection() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "16px", md: "32px" },
        backgroundColor: "#1A2238",
        color: "#fff",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingRight: { md: "40px" },
        }}
      >
        <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image src={hero2} alt="Dental Banner" />
      </Box>
        <Typography
          variant="h2"
          fontWeight="bold"
          gutterBottom
          sx={{ color: "#F48FB1" }}
        >
          UX Case Study
        </Typography>
        <Typography variant="h5" gutterBottom>
          Designing a Landing Page for a Dental Specialist in Toronto
        </Typography>
        <Box
        sx={{
        //   flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image src={hero3} alt="Dental Banner" />
      </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image src={hero1} alt="Dental Banner" />
      </Box>
    </Box>
  );
}

export default DentistHeroSection;
