import React from 'react'
import ContentHeroSection from './ContentHeroSection'
import ContentSectionTwo from './ContentSectionTwo'
import ContentSectionThree from './ContentSectionThree'
import ContentSectionFour from './ContentSectionFour'
import ContentEndSection from './ContentEndSection'
import ContentSectionFive from './ContentSectionFive'
import { TabTitle } from '../../utils/GeneralFunctions'
// import SeoSectionTwo from './SeoSectionTwo'
// import SeoSectionThree from './SeoSectionThree'
// import SeoSectionFour from './SeoSectionFour'
// import SeoSectionFive from './SeoSectionFive'
// import SeoEndSection from './SeoEndSection'

function ContentDesignPage() {
  TabTitle("Content Creation")
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <ContentHeroSection />
      <ContentSectionTwo />
      <div className='relative seo-sec2-background-2 ' >
        <div className='sticky top-0'>
    <ContentSectionThree />
    <ContentSectionFour />
    <ContentSectionFive />

      </div></div>
      <ContentEndSection />
    </div>
  )
}

export default ContentDesignPage
